<template>
    <div
        class="cta-card section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <GradientText
                :size="titleSize" heading
                :variant="background === 'navy-gradient' ? 'lightblue' : 'blue'"
            >
                {{ title }}
            </GradientText>
            <Txt v-if="description" :size="descriptionSize">
                {{ description }}
            </Txt>
            <ul v-if="list && list.length">
                <li v-for="listItem, listIndex in list" :key="listIndex">
                    <Icon name="correct" size="16" right />
                    {{ listItem.title }}
                </li>
            </ul>
            <Button
                v-if="buttonHref || buttonCustomAction"
                variant="create"
                size="s" heading
                :href="buttonCustomAction ? undefined : buttonHref"
                :data-placement="buttonPlacement"
                @click="onCTAClick"
            >
                {{ buttonText }}
            </Button>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { buttonCustomAction, background } from '@/components/author/cms/constants';

import Icon from '@/components/utility/icon';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'CallToActionCard',
    components: { Icon, Button, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Take your content, make it WAY better', cmsType: 'longText' },
        titleSize: { type: String, default: 'xxl', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        description: { type: String, default: '', cmsType: 'longText' },
        descriptionSize: { type: String, default: 'l', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }] },

        buttonText: { type: String, default: 'Give it a go' },
        buttonHref: { type: String, default: '/build' },
        buttonPlacement: { type: String, default: 'bottom' },
        buttonCustomAction,

        background
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.cta-card {
    margin: 0 24px;
    border-radius: 32px;
    padding: 140px 0;
    section {
        text-align: center;
        .txt-component.heading {
            max-width: 800px;
        }
        ul {
            display: grid;
            gap: 12px 40px;
            grid-template-columns: 1fr 1fr;
            margin: 40px 0;
            font-family: var(--body);
            li {
                display: flex;
                align-items: center;
                .icon {
                    color: #FFF;
                    background-color: ~"rgba(var(--coal-rgb), 0.2)";
                    border-radius: 50%;
                    padding: 8px;
                }
            }
        }
        .cobutton {
            padding: 16px 32px;
            border-radius: 48px;
        }
    }
    &.mobile {
        padding: 24px;
        section {
            ul {
                text-align: left;
                grid-template-columns: 1fr;
                gap: 12px;
                margin: 24px 0;
            }
        }
    }
}
</style>
