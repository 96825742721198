<template>
    <blockquote>
        <GradientText size="m" heading>
            {{ quote }}
        </GradientText>
        <Txt size="xs">
            {{ attribution }}    
        </Txt>
    </blockquote>
</template>

<script>
import Txt from '@/components/form/txt';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'ResourceCallout',
    components: { Txt, GradientText },
    inheritAttrs: false,
    props: {
        quote: { type: String, default: '' },
        attribution: { type: String, default: '' }
    }
};
</script>

<style lang="less" scoped>
blockquote {
    margin: 0;
    padding: 0;
    .txt-component.body {
        color: var(--anchor);
        margin-top: 16px;
    }
}
</style>
