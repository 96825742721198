<template>
    <div
        class="stats section-wrapper"
        :class="viewport.is"
    >
        <section>
            <div class="learners">
                <NuxtImg
                    v-if="image"
                    :src="image"
                    :alt="imageAlt"
                    provider="builderio"
                    width="2000"
                    height="1000"
                    sizes="xl:100vw lg:100vw md:100vw sm:100vw xs:100vw"
                />
                <GradientText :size="viewport.is.mobile ? 'l' : 'xxl'" heading variant="lightblue">
                    4,000,000+
                </GradientText>
                <Txt size="xs" heading>
                    Happy learners ❤️
                </Txt>
            </div>
            <div class="companies">
                <Txt :size="viewport.is.mobile ? 'l' : 'xxl'" heading>
                    7,000+
                </Txt>
                <Txt size="xs" heading>
                    Companies trusted us so far 🤝
                </Txt>
            </div>
            <div>
                <Txt size="xs" heading>
                    Engage your team, customers, partners, students, anybody who needs to learn something.
                </Txt>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'Stats',
    components: { Txt, GradientText },
    inheritAttrs: false,
    props: {
        image: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
        imageAlt: { type: String, default: 'A Coassemble course and examples of documents you could convert them from' },
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.stats.section-wrapper {
    section {
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--navy);
        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 32px;
            border-radius: 24px;
            flex: 0 0 100%;
            background: linear-gradient(292deg, #9747FF -40.75%, #F6F7F8 55.74%);
            .txt-component {
                position: relative;
            }
            &.learners {
                position: relative;
                flex: 1 1;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 3.13%, rgba(0, 0, 0, 0) 71.88%);
                color: #FFF;
                height: 464px;
                min-width: 0;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 24px;
                }
            }
            &.companies {
                flex: 0 0;
                aspect-ratio: 1;
                background: linear-gradient(43deg, #98E5FF -25.9%, #F5F7F8 63.66%, #F6F7F8 63.67%);
                height: 464px;
            }
        }
    }
    &.lg {
        section {
            > div {
                &.learners, &.companies {
                    flex: 1 1;
                    aspect-ratio: unset;
                }
            }
        }
    }
    &.tablet {
        padding: 24px;
        section {
            > div {
                &.companies {
                    aspect-ratio: unset;
                    flex: 0 0 100%;
                }
            }
        }
    }
    &.mobile {
        padding: 24px;
        section {
            > div {
                flex: 0 0 100%;
                &.learners {
                    height: auto;
                    aspect-ratio: 1;
                }
                &.companies {
                    aspect-ratio: 1;
                    height: auto;
                }
            }
        }
    }
}
</style>
