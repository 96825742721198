<template>
    <div class="image-marquee section-wrapper" :class="viewport.is">
        <ClientOnly>
            <section>
                <Marquee :count="10" :speed="30">
                    <div class="slides">
                        <div
                            v-for="image, imageIndex in images"
                            :key="imageIndex"
                            class="slide"
                        >
                            <NuxtImg
                                :src="image.image"
                                :alt="image.imageAlt"
                                provider="builderio"
                                width="1200"
                                height="816"
                                sizes="xl:50vw lg:50vw md:50vw sm:50vw xs:50vw"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </Marquee>
            </section>
        </ClientOnly>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Marquee from '@/components/animation/marquee';

export default {
    name: 'CustomImageMarquee',
    components: { Marquee },
    inheritAttrs: false,
    props: {
        images: {
            type: Array,
            default: null,
            subFields: [
                { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'imageAlt', type: 'string' }
            ]
        }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.image-marquee {
    padding: 40px 0;
    section {
        width: 100%;
        max-width: unset;
        flex-direction: row;
        gap: 8px;
        .marquee { flex: 1; }
        .slides {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            .slide {
                margin-right: 16px;
                width: 20vw;
                min-width: 240px;
                aspect-ratio: 3 / 2.1;
                position: relative;
                img {
                    border-radius: 12px;
                    object-fit: cover;
                }
            }
        }
    }
}
</style>
