<template>
    <div
        class="card-grid-section section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <div class="card-grid-content">
                <GradientText size="xl" heading>
                    {{ title }}
                </GradientText>
                <Txt v-if="description" size="l">
                    {{ description }}
                </Txt>
            </div>
            <hr v-if="background === 'white'" />
            <div class="grid">
                <div
                    v-for="listItem, listIndex in list"
                    :key="listIndex"
                    class="card-item"
                    :class="`padding-${padding}`"
                >
                    <NuxtImg
                        v-if="listItem.icon"
                        :src="listItem.icon"
                        provider="builderio"
                        width="56"
                        height="56"
                        sizes="xl:10vw lg:10vw md:10vw sm:10vw xs:10vw"
                        loading="lazy"
                        role="presentation"
                        alt=""
                    />
                    <Txt
                        tag="h3"
                        size="m" heading
                    >
                        {{ listItem.title }}
                    </Txt>
                    <Txt size="m">
                        {{ listItem.description }}
                    </Txt>
                    <NuxtImg
                        v-if="listItem.image"
                        :src="listItem.image"
                        :alt="listItem.imageAlt"
                        provider="builderio"
                        width="400"
                        height="360"
                        sizes="xl:50vw lg:50vw md:50vw sm:50vw xs:50vw"
                        loading="lazy"
                        class="card-image"
                    />
                </div>
            </div>
            <div v-if="buttonHref || buttonCustomAction" class="card-grid-footer">
                <Txt size="l">
                    {{ buttonDescription }}
                </Txt>
                <Button
                    variant="create"
                    size="s" heading
                    :href="buttonCustomAction ? undefined : buttonHref"
                    :data-placement="buttonPlacement"
                    @click="onCTAClick"
                >
                    {{ buttonText }}
                </Button>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { buttonCustomAction, background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'CardGridSection',
    components: { Txt, Button, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Transform it', cmsType: 'longText' },
        description: { type: String, default: 'Turn any topic into a ridiculously good-looking course. In no time.' },
        background,
        padding: { type: String, default: 'md', enum: ['sm', 'md', 'lg'] },

        buttonText: { type: String, default: 'Give it a go' },
        buttonHref: { type: String, default: '/build' },
        buttonPlacement: { type: String, default: 'middle' },
        buttonCustomAction,
        buttonDescription: { type: String, default: 'Have questions about transforming your content?' },

        list: {
            type: Array,
            default: null,
            subFields: [
                { name: 'title', type: 'string' },
                { name: 'description', type: 'longText' },
                { name: 'icon', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'imageAlt', type: 'string' }
            ]
        }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.card-grid-section {
    position: relative;
    padding: 80px;
    background-color: var(--cloud);
    border-bottom: 1px solid var(--mercury);
    section {
        align-items: center;
        text-align: center;
        gap: 64px;
        > .txt-component { max-width: 600px; }
    }
    .card-grid-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }
    hr {
        margin: 0;
        width: 100%;
        border: 1px solid var(--smoke);
    }
    .grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 64px;
        .card-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            gap: 16px;
            background-color: #FFF;
            border-radius: 20px;
            .txt-component.heading { color: var(--navy); }
            .card-image {
                width: 100%;
                border-radius: 12px;
            }
            &.padding-sm { padding: 24px; }
            &.padding-md { padding: 48px; }
            &.padding-lg { padding: 64px; }
        }
    }
    .card-grid-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .cobutton {
            padding: 14px 32px;
        }
    }
    &.tablet {
        padding: 48px;
        .grid {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }
    &.mobile {
        .grid {
            grid-template-columns: 1fr;
        }
    }
}
</style>
