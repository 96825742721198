<template>
    <div
        class="contents-section section-wrapper"
        :class="viewport.is"
    >
        <section>
            <div class="contents-grid">
                <div class="contents-grid-content">
                    <GradientText
                        tag="h2"
                        size="xxl" heading
                        flex
                    >
                        {{ title }}
                        <svg viewBox="0 0 350 159" xmlns="http://www.w3.org/2000/svg">
                            <path d="M331.94 9.20413L338.198 6.71819L340.514 5.29329e-05L342.829 6.71819L349.087 9.20413L342.829 11.6901L340.514 18.4082L338.198 11.6901L331.94 9.20413Z" fill="#9AE6F3" />
                            <path d="M315.68 12.2686L319.582 11.4409L321.026 9.20411L322.47 11.4409L326.372 12.2686L322.47 13.0962L321.026 15.333L319.582 13.0962L315.68 12.2686Z" fill="#9AE6F3" />
                        </svg>
                    </GradientText>
                    <Txt>
                        {{ description }}
                    </Txt>
                </div>
                <div v-if="viewport.is.desktop" class="contents-grid-links">
                    <div v-if="list">
                        <ul>
                            <li v-for="item in list" :key="item.title">
                                <a :href="item.href">
                                    <Txt tag="h3">
                                        {{ item.title }}
                                    </Txt>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'ContentsSection',
    components: { Txt, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Take your content, make it WAY better', cmsType: 'longText' },
        description: { type: String, default: 'With a few clicks, you can transform your content into beautiful courses that will look like a professional created it, without the cost.' },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }, { name: 'href', type: 'string' }] },
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
};
</script>

<style lang="less" scoped>
.section-wrapper.contents-section {
    padding: 96px 48px;
    .contents-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 424px 424px;
        justify-content: center;
        gap: 48px;
        .contents-grid-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 48px 0;
            gap: 8px;
            .txt-component { position: relative; }
            svg {
                position: absolute;
                left: 15%;
                right: -15%;
            }
        }
        .contents-grid-links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            overflow: hidden;
            a { text-decoration: underline; }
        }
    }
    &.tablet {
        padding: 24px 0;
        .contents-grid {
            grid-template-columns: 1fr;
            gap: 32px;
            .contents-grid-links {
                order: 1;
            }
            .contents-grid-content {
                order: 2;
                padding: 16px 24px;
            }
        }
    }
}
</style>
