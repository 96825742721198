<template>
    <div class="not-found">
        <div>
            <NotFoundGraphic />
            <Txt size="xl" heading tag="h1">
                Page not found
            </Txt>
            <Txt size="l">
                Looks like something went completely wrong!
                <br />
                But dont worry - it can happen to the best of us, and it just happened to you.
            </Txt>
            <Button
                variant="create"
                size="xs" heading
                href="https://coassemble.com/"
            >
                Go to home
            </Button>
        </div>
    </div>
</template>

<script>
import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';

import NotFoundGraphic from '@/assets/website/404.svg';

export default {
    name: 'NotFound',
    components: { Txt, Button, NotFoundGraphic },
};
</script>

<style lang="less" scoped>
.not-found {
    padding: 200px 0;
    background-color: var(--cloud);
    > div {
        max-width: 1170px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        .txt-component.heading { color: var(--navy); }
        .cobutton { padding: 16px 24px; }
    }
}
</style>
