<template>
    <figure>
        <NuxtImg
            v-if="image"
            :src="image"
            :alt="imageAlt || caption"
            provider="builderio"
            width="2000"
            height="1000"
            sizes="xl:150vw lg:150vw md:150vw sm:150vw xs:150vw"
            loading="lazy"
        />
        <figcaption v-if="caption">
            <Txt size="xs">
                {{ caption }}    
            </Txt>
        </figcaption>
    </figure>
</template>

<script>
import Txt from '@/components/form/txt';

export default {
    name: 'ResourceImage',
    components: { Txt },
    inheritAttrs: false,
    props: {
        image: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
        imageAlt: { type: String, default: '' },
        caption: { type: String, default: '' }
    }
};
</script>

<style lang="less" scoped>
figure {
    img {
        border-radius: 12px;
    }
    .txt-component.body {
        color: var(--anchor);
        margin-top: 16px;
    }
}
</style>
