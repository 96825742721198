export const buttonCustomAction = {
    type: String,
    default: '',
    enum: ['', 'contact', 'transform', 'transform-video', 'generate', 'generate-video', 'enquiry', 'headless-video', 'scroll-to-top']
};

export const background = {
    type: String,
    default: null,
    enum: ['white', 'cloud', 'lightblue', 'navy-gradient', 'cloud-gradient', 'lightblue-gradient']
};
