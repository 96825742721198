<template>
    <Observer
        type="intersection"
        namespace="marqueeIntersection"
        class="marquee"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @update="isIntersecting = $event.entry.isIntersecting"
    >
        <span
            class="marquee-mover"
            :style="{ transform: `translateX(-${offset}px)` }"
        >
            <Observer
                class="marquee-section"
                type="resize"
                namespace="marqueeResize"
                @update="setSectionWidth"
            >
                <slot />
            </Observer>
            <span
                v-for="index in duplicateCount"
                :key="index"
                class="marquee-section"
                aria-hidden="true"
            >
                <slot />
            </span>
        </span>
    </Observer>
</template>

<script>
import { animate } from '@/helpers/animations';
import Observer from '@/components/utility/observer';

export default {
    name: 'Marquee',
    components: { Observer },
    props: {
        play: { type: Boolean, default: true },
        speed: { type: Number, default: 20 },
        count: { type: Number, default: 1, validator: v => Number.isInteger(v) }
    },
    data() {
        return {
            offset: 0,
            sectionWidth: 1,
    
            isHovered: false,
            pointerTimer: null,
    
            isIntersecting: false
        };
    },
    computed: {
        duplicateCount() {
            return Math.max(1, this.count);
        },
        isPaused() {
            if (!this.isIntersecting) {
                return true;
            }
            if (!this.play) {
                return true;
            }
            if (this.isHovered) {
                return true;
            }
            return false;
        }
    },
    watch: {
        isPaused(to) {
            if (to) return;

            this.moveRibbon();
        },
    },
    mounted() {
        if (!this.isPaused) {
            this.moveRibbon();
        }
    },
    methods: {
        setSectionWidth({ entry }) {
            const width = entry.borderBoxSize
                ? entry.borderBoxSize[0].inlineSize
                : entry.contentRect.width;

            this.sectionWidth = Math.max(1, width);
        },
        async moveRibbon() {
            const start = this.offset === this.sectionWidth ? 0 : this.offset;
            const duration = (this.sectionWidth * this.speed) * ((this.sectionWidth - start) / this.sectionWidth);

            const callback = (offset) => {
                if (this.isPaused) return true;
                this.offset = offset;
            };
            await animate(this.sectionWidth, start, callback, { duration });
            if (this.isPaused) return;

            this.moveRibbon();
        }
    }
};
</script>

<style lang="less" scoped>
.marquee {
    display: block;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;
    .marquee-mover { display: flex; }
    .marquee-section { display: block; }
}
</style>
