<template>
    <div
        class="accordion-section section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <div>
                <GradientText :size="viewport.is.mobile ? 'l' : 'xl'" heading>
                    {{ title }}
                </GradientText>
                <Txt v-if="description" size="l">
                    {{ description }}
                </Txt>
            </div>
            <ClientOnly>
                <ul class="accordion">
                    <li v-for="item, listIndex in list" :key="listIndex">
                        <Clickable
                            @click="toggle(listIndex)"
                        >
                            <span v-if="type === 'number'" class="number">
                                <Txt size="xxs" heading>
                                    {{ listIndex + 1 }}
                                </Txt>
                            </span>
                            <Txt
                                tag="h3"
                                :size="viewport.is.mobile ? 'xxs' : 'm'" heading
                            >
                                {{ item.title }}
                            </Txt>
                            <Icon v-if="type === 'accordion'" :name="isExpanded === listIndex ? 'minus' : 'plus'" />
                        </Clickable>
                        <Expand>
                            <div v-if="isExpanded === listIndex" class="description">
                                <Txt v-html="item.description" />
                            </div>
                        </Expand>
                    </li>
                </ul>
            </ClientOnly>
        </section>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useResponsive } from '@/store/responsive';
import { background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Clickable from '@/components/utility/clickable';
import GradientText from '@/components/animation/gradient-text';

const Expand = defineAsyncComponent(() => import('@/components/animation/expand'));

export default {
    name: 'Accordion',
    components: { Txt, Icon, Expand, Clickable, GradientText },
    inheritAttrs: false,
    props: {
        title: { type: String, default: 'Things you might be wondering' },
        description: { type: String, default: '' },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }, { name: 'description', type: 'richText' }] },
        type: { type: String, default: 'accordion', enum: ['accordion', 'number'] },

        background
    },
    data() {
        return {
            isExpanded: null,
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        toggle(index) {
            this.isExpanded = index === this.isExpanded ? null : index;
            this.$track.event('Accordion Toggled', {
                'expanded': this.isExpanded === index,
                'accordion_title': this.list[index].title,
            });
        }
    }
};
</script>

<style lang="less" scoped>
.accordion-section.section-wrapper {
    margin: 24px;
    border-radius: 32px;
    section {
        flex-direction: row;
        align-items: flex-start;
        > div {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        ul {
            flex: 0 0 60%;
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;
            display: flex;
            flex-direction: column;
            li {
                flex: 1;
                display: flex;
                flex-direction: column;
                background-color: #FFF;
                border-radius: 12px;

                button {
                    width: 100%;
                    padding: 24px;
                    display: flex;
                    align-items: center;
                    > .txt-component.heading {
                        flex: 1;
                        color: var(--navy);
                    }
                }
                .number {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: var(--primary);
                    color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;
                }
                .description .txt-component {
                    padding: 0 32px 24px;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    &.mobile {
        padding: 24px;
        section {
            flex-wrap: wrap;
            ul {
                flex: 1;
                li {
                    button {
                        padding: 16px;
                    }
                    .description .txt-component {
                        padding: 0 16px 16px;
                    }
                }
            }
        }
    }
}
</style>
