<template>
    <div
        class="tabs-section section-wrapper"
        :class="viewport.is"
    >
        <nav>
            <ul>
                <li
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{ active: index === selectedIndex }"
                >
                    <Clickable @click="select(index)">
                        <Txt size="m" heading>
                            {{ tab.title }}
                        </Txt>
                        <NuxtImg
                            v-if="tab.image"
                            :src="tab.image"
                            :alt="tab.imageAlt"
                            provider="builderio"
                            width="150"
                            height="56"
                            sizes="xl:20vw lg:20vw md:20vw sm:20vw xs:20vw"
                        />
                        <Button
                            :icon="selectedIndex === index ? 'chevron-down' : 'chevron-right'"
                            variant="create"
                            disabled
                            class="visually-enabled"
                        />
                    </Clickable>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';
import Clickable from '@/components/utility/clickable';

export default {
    name: 'TabsSection',
    canHaveChildren: true,
    components: { Txt, Button, Clickable },
    inject: ['apiKey', 'customComponents'],
    inheritAttrs: false,
    props: {
        tabs: {
            type: Array,
            default: null,
            subFields: [
                { name: 'title', type: 'string' },
                { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'imageAlt', type: 'string' },
                { name: 'target', type: 'string', defaultValue: 'tab1' }
            ]
        }
    },
    data() {
        return {
            selectedIndex: 0,
            viewport: { is: {} }
        };
    },
    mounted() {
        this.$bus.on('tab:select', this.selectById);
        this.viewport = useResponsive();
        this.select(0);
    },
    methods: {
        select(index) {
            this.selectedIndex = index;
            this.tabs.forEach((tab, i) => {
                const target = document.getElementById(tab.target);
                if (target) target.style.display = i === index ? 'block' : 'none';
            });
        },
        selectById(id) {
            const index = this.tabs.findIndex(tab => tab.target === id);
            if (~index) this.select(index);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.tabs-section {
    background-color: var(--cloud);
    padding: 0;
    nav {
        padding: 80px 32px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            gap: 32px;

            li {
                padding: 0;
                margin: 0;
                margin-bottom: -3px;
                .txt-component { color: var(--navy); }
                .clickable {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    padding: 48px;
                    border-radius: 20px 20px 0 0;
                    border: 1.5px solid #FFF;
                    width: 520px;
                    img {
                        opacity: 0.5;
                    }
                }
                .cobutton {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    margin: 24px;
                }
                &.active .clickable {
                    background-color: #FFF;
                    img { opacity: 1;}
                }
            }
        }
    }
}
</style>
