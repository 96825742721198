<template>
    <hr />
</template>

<script>
export default {
    name: 'ResourceDivider'
};
</script>

<style lang="less" scoped>
hr {
    margin: 8px 0;
    width: 100%;
    background: var(--steel);
    height: 1px;
}
</style>
