<template>
    <div class="screens-marquee section-wrapper" :class="viewport.is">
        <ClientOnly>
            <section>
                <Marquee :count="10" :speed="30">
                    <div class="slides">
                        <div
                            v-for="n in 6"
                            :key="n"
                            class="slide"
                        >
                            <NuxtImg
                                :src="`/carousel/screen${n}.jpg`"
                                :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                                width="1200"
                                height="816"
                                sizes="xl:20vw lg:20vw md:20vw sm:20vw xs:20vw"
                                loading="lazy"
                                alt=""
                                role="presentation"
                            />
                        </div>
                    </div>
                </Marquee>
            </section>
        </ClientOnly>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Marquee from '@/components/animation/marquee';

export default {
    name: 'ScreensMarquee',
    components: { Marquee },
    inheritAttrs: false,
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.screens-marquee {
    padding: 40px 0;
    section {
        width: 100%;
        max-width: unset;
        flex-direction: row;
        gap: 8px;
        .marquee { flex: 1; }
        .slides {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            .slide {
                margin-right: 16px;
                width: 20vw;
                min-width: 240px;
                aspect-ratio: 3 / 2.1;
                position: relative;
                img {
                    border-radius: 12px;
                }
            }
        }
    }
    &.tablet {
        padding: 32px 0;
    }
}
</style>
