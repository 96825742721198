<template>
    <div
        class="customer-logos section-wrapper"
        :class="[viewport.is, `padding-${padding}`]"
    >
        <Txt v-if="heading" align="center">
            Trusted & loved by over 2,000 L&D legends...
        </Txt>
        <ClientOnly>
            <section>
                <HotTopic />
                <Userlane />
                <G2 class="g2" />
                <Volt />
                <Hopper />
            </section>
        </ClientOnly>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';

import HotTopic from '@/assets/website/logos/hot-topic.svg';
import Userlane from '@/assets/website/logos/userlane.svg';
import G2 from '@/assets/website/logos/g2.svg';
import Volt from '@/assets/website/logos/volt.svg';
import Hopper from '@/assets/website/logos/hopper.svg';

export default {
    name: 'CustomerLogos',
    components: { Txt, HotTopic, Userlane, G2, Volt, Hopper },
    inheritAttrs: false,
    props: {
        heading: { type: Boolean, default: true },
        padding: { type: String, default: 'large', enum: ['large', 'medium'] }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.customer-logos {
    .txt-component {
        margin-bottom: 32px;
        color: var(--navy);
        font-weight: bold;
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 96px;
        fill: var(--navy);
        justify-items: center;
        svg {
            width: 100%;
            max-width: 120px;
            max-height: 24px;
            &.g2 { max-height: unset; }
        }
    }
    &.lg {
        section { gap: 32px; }
    }
    &.tablet {
        section {
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0 24px;
            max-width: 440px;
            gap: 24px;
            grid-auto-flow: column;
            .g2 {
                grid-row: span 2;
            }
        }
    }
    &.mobile {
        svg { scale: 0.75; }
        .g2 { scale: 1; }
    }
    &.padding-large {
        padding: 120px 64px;
        &.mobile { padding: 80px 64px; }
    }
    &.padding-medium {
        padding: 80px 64px;
        &.mobile { padding: 64px 24px; }
    }
}
</style>
