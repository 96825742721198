<template>
    <div :id="_.uid" class="resource-paragraph">
        <Txt
            v-if="title"
            tag="h3"
            size="m" heading
        >
            {{ title }}
        </Txt>
        <ClientOnly>
            <Txt
                v-if="description"
                size="l"
                v-html="description"
            />
        </ClientOnly>
    </div>
</template>

<script>
import Txt from '@/components/form/txt';

export default {
    name: 'ResourceParagraph',
    components: { Txt },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: '' },
        description: { type: String, default: '', cmsType: 'richText' }
    },
    mounted() {
        this.$bus.emit('resource:anchor', { id: this._.uid, title: this.title });
    },
};
</script>

<style lang="less">
.resource-paragraph {
    .txt-component.heading {
        margin: 0;
        margin-bottom: 24px;
    }
}
</style>
