<template>
    <div
        class="rich-text-section section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <Txt :size="descriptionSize" :align="descriptionAlignment">
                <div v-html="description" />
            </Txt>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';

export default {
    name: 'RichTextSection',
    components: { Txt },
    inheritAttrs: false,
    props: {
        description: { type: String, default: '', cmsType: 'richText' },
        descriptionSize: { type: String, default: 'l', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        descriptionAlignment: { type: String, default: 'left', enum: ['left', 'center', 'right'] },
        background
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less">
.section-wrapper.rich-text-section {
    section {
        align-items: flex-start;
        text-align: left;
        ul:not(.accordion) {
            list-style: disc;
            padding-left: 24px;
            margin: 12px 0;
            li { display: list-item; }
        }
        h1, h2, h3, h4, h5, h6 {
            margin: 24px 0;
        }
    }
}
</style>
