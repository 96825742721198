<template>
    <div :id="_.uid" class="resource-accordion">
        <Txt
            v-if="title"
            tag="h3"
            size="m" heading
        >
            {{ title }}
        </Txt>
        <ClientOnly>
            <ul class="accordion">
                <li v-for="item, listIndex in list" :key="listIndex">
                    <Clickable
                        @click="listIndex === isExpanded ? isExpanded = null : isExpanded = listIndex"
                    >
                        <span v-if="type === 'number'" class="number">
                            <Txt size="xxs" heading>
                                {{ listIndex + 1 }}
                            </Txt>
                        </span>
                        <Icon v-if="type === 'accordion'" :name="isExpanded === listIndex ? 'minus' : 'plus'" />
                        <Txt
                            tag="h3"
                            size="s" heading
                        >
                            {{ item.title }}
                        </Txt>
                    </Clickable>
                    <Expand>
                        <div v-if="isExpanded === listIndex" class="description">
                            <Txt v-html="item.description" />
                        </div>
                    </Expand>
                </li>
            </ul>
        </ClientOnly>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Clickable from '@/components/utility/clickable';

const Expand = defineAsyncComponent(() => import('@/components/animation/expand'));

export default {
    name: 'ResourceAccordion',
    components: { Txt, Icon, Expand, Clickable },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: '' },
        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }, { name: 'description', type: 'richText' }] },
        type: { type: String, default: 'accordion', enum: ['accordion', 'number'] },
    },
    data() {
        return {
            isExpanded: {}
        };
    },
    mounted() {
        this.$bus.emit('resource:anchor', { id: this._.uid, title: this.title });
    },
};
</script>

<style lang="less" scoped>
.resource-accordion {
    > .txt-component.heading {
        margin: 0;
        margin-bottom: 24px;
    }
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: var(--cloud);
        border-radius: 16px;
        button {
            width: 100%;
            padding: 16px;
            display: flex;
            align-items: center;
            gap: 12px;
            > .txt-component.heading {
                flex: 1;
                color: var(--navy);
            }
        }
        .number {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: var(--primary);
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .description .txt-component {
            padding: 16px 24px;
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
</style>
