<template>
    <div
        class="enquiry-form section-wrapper"
        :class="viewport.is"
    >
        <section>
            <div class="enquiry-content">
                <GradientText size="xl" heading>
                    {{ isSubmitted ? 'Thank you for your enquiry' : title }}
                </GradientText>
                <Txt size="l">
                    <span v-html="description" />
                </Txt>
            </div>
            <ClientOnly>
                <form
                    v-if="!isSubmitted"
                    id="enquiry"
                    ref="form"
                    @submit.prevent="submit"
                >
                    <Field
                        v-model:value="form.name"
                        name="name"
                        show-info-always
                    >
                        <Txt size="xxs" heading>
                            Name*
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.email"
                        type="email"
                        name="email"
                        show-info-always
                    >
                        <Txt size="xxs" heading>
                            Work email*
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.phone"
                        v-model:area-code="form.areaCode"
                        type="tel"
                        name="phone"
                        show-info-always
                    >
                        <Txt size="xxs" heading>
                            Mobile number*
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.company"
                        name="company"
                        show-info-always
                    >
                        <Txt size="xxs" heading>
                            Company URL
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.help"
                        type="select"
                        name="help"
                        show-info-always
                        :options="[
                            'Authoring at scale',
                            'Learning Management System',
                            'Content Distribution platform',
                            'Headless Learning for customer training at scale',
                            'Headless Authoring for people tech integrations',
                            'Course design services for world class course delivery'
                        ]"
                    >
                        <Txt size="xxs" heading>
                            How can our sales team help?
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.explore"
                        type="select"
                        name="explore"
                        show-info-always
                        :options="[
                            'Send me some collateral to read via email',
                            'A quick chat via SMS',
                            'Watch a quick demo video',
                            'An exploration call',
                            'Let me take it for a spin'
                        ]"
                    >
                        <Txt size="xxs" heading>
                            How do you like to explore products?
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.useCase"
                        name="use-case"
                        type="textarea"
                        :rows="4"
                        show-info-always
                    >
                        <Txt size="xxs" heading>
                            Anything else you'd like us to know?
                        </Txt>
                    </Field>
                    <Field
                        v-model:value="form.check"
                        name="check"
                        class="visually-hidden"
                    >
                        <Txt size="xxs" heading>
                            Confirm you are human
                        </Txt>
                    </Field>
                    <Txt size="xxs" heading>
                        *Required
                    </Txt>
                </form>
            </ClientOnly>
            <Button
                v-if="!isSubmitted"
                variant="create"
                size="s" heading
                type="submit"
                form="enquiry"
                :status="status"
                class="visually-enabled"
                :disabled="!form.name || !form.email || !form.phone"
            >
                Send enquiry
            </Button>
        </section>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

const Field = defineAsyncComponent(() => import('@/components/form/field'));

export default {
    name: 'EnquiryForm',
    components: { Txt, Field, Button, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Request a free consult', cmsType: 'longText' },
        description: { type: String, default: '', cmsType: 'richText' },
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                areaCode: '',
                company: '',
                help: '',
                explore: '',
                useCase: '',
                check: false
            },
            status: null,
            isSubmitted: false,
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
        this.$bus.on('cms:enquiry', () => {
            this.$refs.form.scrollIntoView({ behavior: 'smooth' });
            this.$track.event('Enquiry Form Opened');
        });
    },
    methods: {
        submit() {
            if (this.form.check) return this.isSubmitted = true;
            this.status = 'waiting';
            return this.$http.post('/enquiry', this.form)
                .then(() => {
                    this.$track.event('Enquiry Form Submitted', {
                        'full_name': this.form.name,
                        'email': this.form.email,
                        'phone_number': this.form.areaCode + ' ' + this.form.phone,
                        'company_url': this.form.company,
                        'interest': this.form.help,
                        'how_to_explore': this.form.explore,
                        'enquiry': this.form.useCase
                    });
                    this.isSubmitted = true;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.enquiry-form {
    position: relative;
    padding: 80px;
    background-color: var(--cloud);
    section {
        gap: 48px;
    }
    .enquiry-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 600px;
    }
    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 620px;
        width: 100%;
        padding: 32px;
        background-color: #FFF;
        border-radius: 20px;
        .field { width: 100%; }
        .txt-component.heading { color: var(--navy); }
    }
    .cobutton {
        padding: 14px 32px;
    }
    &.tablet {
        padding: 48px;
    }
}
</style>
<style lang="less">
.section-wrapper.enquiry-form {
    .enquiry-content {
        ul {
            list-style-type: disc;
            padding: 0 0 0 24px;
            margin-top: 24px;
            li { display: list-item; }
        }
    }
}
</style>

