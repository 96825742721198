<template>
    <div
        class="step-section section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <div class="step-grid">
                <div class="step-grid-left">
                    <Txt
                        v-if="step"
                        tag="h2"
                        size="m" heading
                    >
                        {{ step }}
                    </Txt>
                    <Txt :size="viewport.is.mobile ? 'l' : 'xl'" heading>
                        <GradientText
                            :size="viewport.is.mobile ? 'l' : 'xl'" heading
                            :variant="background === 'navy-gradient' ? 'lightblue' : 'blue'"
                        >
                            {{ title }}
                        </GradientText>
                        {{ emoji }}
                    </Txt>
                    <Txt size="l">
                        {{ description }}
                    </Txt>
                    <slot name="left" />
                </div>
                <div class="step-grid-right">
                    <Button
                        v-if="buttonHref || buttonCustomAction"
                        variant="create"
                        size="s" heading
                        :href="buttonCustomAction ? undefined : buttonHref"
                        :data-placement="buttonPlacement"
                        @click="onCTAClick"
                    >
                        {{ buttonText }}
                    </Button>
                    <ul v-if="list && list.length">
                        <li v-for="listItem, listIndex in list" :key="listIndex">
                            <Icon name="correct" right />
                            {{ listItem.title }}
                        </li>
                    </ul>
                    <slot name="right" />
                </div>
            </div>
            <NuxtImg
                v-if="image"
                :src="image"
                :alt="imageAlt"
                provider="builderio"
                :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                width="2000"
                height="1000"
                sizes="xl:150vw lg:150vw md:150vw sm:150vw xs:150vw"
                loading="lazy"
            />
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { buttonCustomAction, background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'StepSection',
    components: { Txt, Icon, Button, GradientText },
    inheritAttrs: false,
    props: {
        step: { type: String, default: null },
        title:  { type: String, default: 'Take your content, make it WAY better', cmsType: 'longText' },
        emoji:  { type: String, default: '' },
        description: { type: String, default: 'With a few clicks, you can transform your content into beautiful courses that will look like a professional created it, without the cost.' },

        image: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
        imageAlt: { type: String, default: 'A Coassemble course and examples of documents you could convert them from' },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }] },

        buttonText: { type: String, default: 'Give it a go' },
        buttonHref: { type: String, default: '/build' },
        buttonPlacement: { type: String, default: 'middle' },
        buttonCustomAction,

        background
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    computed: {
        isCMSImage() {
            return this.image.includes('cdn.builder.io');
        }
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.step-section {
    padding: 80px;
    padding-bottom: 0;
    margin: 0 24px;
    border-radius: 32px;
    .txt-component.heading { display: inline; }
    ul { color: var(--primary); }

    .step-grid {
        width: 100%;
        display: flex;
        padding: 48px 0px;
        gap: 200px;
        align-items: flex-start;
        align-content: center;
        flex-wrap: wrap;
        .step-grid-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            .txt-component.s { color: var(--anchor); }
        }
        .step-grid-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 32px;
            max-width: 360px;
            padding: 0 24px;
            .cobutton {
                padding: 16px 32px;
                border-radius: 48px;
            }
        }
    }
    &.lg {
        .section-grid {
            gap: 32px;
        }
    }
    &.tablet {
        padding: 24px;
        padding-bottom: 0;
        margin: 0;
        border-radius: 0;
        .step-grid {
            flex-direction: column;
            align-items: flex-start;
            align-content: baseline;
            padding: 24px 0;
            gap: 0;
            .step-grid-right {
                padding: 0;
                flex: 0 0 100%;
            }
        }
    }
}
</style>
