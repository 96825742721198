<template>
    <section class="template-article-section">
        <Txt
            v-if="title"
            tag="h2"
            size="l" heading
        >
            {{ title }}
        </Txt>
        <div
            v-if="description"
            v-html="description"
        />
    </section>
</template>

<script>
import Txt from '@/components/form/txt';

export default {
    name: 'CourseTemplateSection',
    components: { Txt },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: '' },
        description: { type: String, default: '', cmsType: 'richText' }
    }
};
</script>

<style lang="less">
section.template-article-section {
    font-family: var(--body);
    h1, h2, h3, h4, h5, h6 {
        color: var(--navy);
    }
    .txt-component.heading {
        margin: 0;
        margin-bottom: 24px;
    }
}
</style>
