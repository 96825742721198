<template>
    <div
        :id="id"
        class="tab-content-section"
    >
        <section>
            <slot />
        </section>
    </div>
</template>

<script>
export default {
    name: 'TabContentSection',
    canHaveChildren: true,
    inject: ['apiKey', 'customComponents'],
    inheritAttrs: false,
    props: {
        id: { type: String, default: 'tab1' }
    }
};
</script>

<style lang="less" scoped>
.tab-content-section {
    min-height: 200px;
}
</style>

