<template>
    <div class="resource-bulleted-list" :class="{ container }">
        <div :id="_.uid">
            <Txt
                v-if="title"
                tag="h3"
                size="m" heading
            >
                {{ title }}
            </Txt>
            <ul>
                <li v-for="item, listIndex in list" :key="listIndex">
                    <ClientOnly>
                        <Txt size="l" v-html="item.description" />
                    </ClientOnly>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Txt from '@/components/form/txt';

export default {
    name: 'ResourceBulletedList',
    components: { Txt },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: '' },
        list: { type: Array, default: null, subFields: [{ name: 'description', type: 'richText' }] },
        container: { type: Boolean, default: false }
    },
    mounted() {
        this.$bus.emit('resource:anchor', { id: this._.uid, title: this.title });
    },
};
</script>

<style lang="less" scoped>
.resource-bulleted-list {
    .txt-component.heading {
        margin: 0;
        margin-bottom: 24px;
    }
    ul {
        width: 100%;
    }
    &.container {
        padding: 24px;
        border-radius: 16px;
        background-color: var(--cloud);
        .txt-component.heading {
            margin-bottom: 32px;
        }
    }
}
</style>
