<template>
    <div
        class="heading section-wrapper"
        :class="viewport.is"
    >
        <section>
            <GradientText :size="titleSize ? titleSize : (viewport.is.mobile ? 'm' : 'xl')" heading>
                {{ title }}
            </GradientText>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'Heading',
    components: { GradientText },
    inheritAttrs: false,
    props: {
        title: { type: String, default: 'Start sharing today' },
        titleSize: { type: String, default: null, enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.heading.section-wrapper {
    section {
        text-align: center;
    }
}
</style>
