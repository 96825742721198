<template>
    <div
        class="grid-section section-wrapper"
        :class="viewport.is"
    >
        <section>
            <svg width="395" height="217" viewBox="0 0 395 217" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="328.256" cy="92.379" r="122.878" stroke="url(#paint0_linear_13_1747)" stroke-width="2" />
                <path d="M250.464 93.0821C206.989 -1.26737 95.2602 -42.5092 0.910736 0.965966L79.6294 171.801L250.464 93.0821Z" fill="url(#paint1_linear_13_1747)" fill-opacity="0.3" />
                <defs>
                    <linearGradient id="paint0_linear_13_1747" x1="328.256" y1="-31.4986" x2="328.256" y2="216.257" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#446CFC" />
                        <stop offset="0.99" stop-color="#446CFC" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_13_1747" x1="131.509" y1="-43.6361" x2="79.6294" y2="171.801" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#446CFC" />
                        <stop offset="1" stop-color="#446CFC" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            <GradientText size="l" heading variant="lightblue">
                {{ title }}
            </GradientText>
            <hr />
            <div class="grid">
                <div
                    v-for="listItem, listIndex in list"
                    :key="listIndex"
                    class="grid-item"
                >
                    <NuxtImg
                        v-if="listItem.image"
                        :src="listItem.image"
                        :alt="listItem.imageAlt"
                        provider="builderio"
                        width="56"
                        height="56"
                        sizes="xl:10vw lg:10vw md:10vw sm:10vw xs:10vw"
                        loading="lazy"
                    />
                    <Txt
                        tag="h3"
                        size="s" heading
                    >
                        {{ listItem.title }}
                    </Txt>
                    <Txt size="m">
                        {{ listItem.description }}
                    </Txt>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'GridSection',
    components: { Txt, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Transform it', cmsType: 'longText' },
        list: {
            type: Array,
            default: null,
            subFields: [
                { name: 'title', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'imageAlt', type: 'string' }
            ]
        }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.grid-section {
    position: relative;
    padding: 100px 40px;
    margin: 24px;
    border-radius: 12px;
    background: linear-gradient(180deg, var(--navy) 0%, #141E64 100%);
    section {
        align-items: flex-start;
        gap: 48px;
        > .txt-component.heading { max-width: 560px; }
        hr {
            width: 100%;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }
        > svg {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 64px;
        .grid-item {
            display: flex;
            flex-direction: column;
            gap: 24px;
            color: #FFF;
            svg { margin-bottom: 32px; }
            .txt-component.body { color: var(--cloud); }
        }
    }
    &.tablet {
        padding: 48px;
        .grid {
            grid-template-columns: 1fr 1fr;
            gap: 40px;
        }
    }
    &.mobile {
        .grid {
            grid-template-columns: 1fr;
        }
    }
}
</style>
