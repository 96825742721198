<template>
    <div
        class="cta section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <GradientText :size="titleSize ? titleSize : (viewport.is.mobile ? 40 : (viewport.is.xl ? 84 : (viewport.is.lg ? 72 : 56)))" heading>
                {{ title }}
            </GradientText>
            <Txt v-if="description" :size="descriptionSize">
                {{ description }}
            </Txt>
            <ul v-if="list && list.length">
                <li v-for="listItem, listIndex in list" :key="listIndex">
                    <Icon name="correct" right />
                    {{ listItem.title }}
                </li>
            </ul>
            <div class="actions">
                <slot>
                    <Button
                        v-if="buttonText || buttonHref || buttonCustomAction"
                        variant="create"
                        :size="viewport.is.mobile ? 's' : 'm'" heading
                        :href="buttonCustomAction ? undefined : buttonHref"
                        :data-placement="buttonPlacement"
                        @click="onCTAClick"
                    >
                        {{ buttonText }}
                    </Button>
                    <Button
                        v-if="secondaryButtonText"
                        variant="ghost"
                        :size="viewport.is.mobile ? 's' : 'm'" heading
                        :href="secondaryButtonCustomAction ? undefined : secondaryButtonHref"
                        :data-placement="secondaryButtonPlacement"
                        @click="onSecondaryClick"
                    >
                        {{ secondaryButtonText }}
                    </Button>
                </slot>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { buttonCustomAction, background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'CallToAction',
    components: { Txt, Icon, Button, GradientText },
    inheritAttrs: false,
    props: {
        title: { type: String, default: 'Start sharing today' },
        titleSize: { type: String, default: null, enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        description: { type: String, default: 'Trusted & loved by over 2,000 L&D legends... What are you waiting for...' },
        descriptionSize: { type: String, default: 'l', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }] },

        buttonText: { type: String, default: 'Let\'s go' },
        buttonHref: { type: String, default: '/build' },
        buttonPlacement: { type: String, default: 'cta' },
        buttonCustomAction,

        secondaryButtonText: { type: String, default: '' },
        secondaryButtonHref: { type: String, default: '/features' },
        secondaryButtonPlacement: { type: String, default: 'cta' },
        secondaryButtonCustomAction: buttonCustomAction,

        background
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        },
        onSecondaryClick(event) {
            if (this.secondaryButtonCustomAction) return this.$bus.emit(`cms:${this.secondaryButtonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.cta {
    padding: 96px 32px;
    section {
        text-align: center;
        gap: 40px;
        ul {
            display: flex;
            align-items: center;
            gap: 24px;
            font-family: var(--body);
            li {
                display: flex;
                align-items: center;
            }
        }
        .cobutton {
            padding: 18px 64px;
            border-radius: 56px;
            max-width: 100%;
        }
        .actions {
            display: flex;
            gap: 40px;
            justify-content: center;
        }
    }
    &.mobile {
        padding: 64px 24px;
        section {
            gap: 24px;
            ul {
                flex-direction: column;
                gap: 8px;
            }
            .cobutton { padding: 14px 32px; }
            .actions {
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}
</style>
