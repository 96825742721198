<template>
    <ClientOnly>
        <ul class="accordion">
            <li v-for="item, listIndex in list" :key="listIndex">
                <Clickable
                    @click="listIndex === isExpanded ? isExpanded = null : isExpanded = listIndex"
                >
                    <span v-if="type === 'number'" class="number">
                        <Txt size="xxs" heading>
                            {{ listIndex + 1 }}
                        </Txt>
                    </span>
                    <Txt
                        tag="h3"
                        size="s" heading
                    >
                        {{ item.title }}
                    </Txt>
                    <Icon v-if="type === 'accordion'" :name="isExpanded === listIndex ? 'minus' : 'plus'" />
                </Clickable>
                <Expand>
                    <div v-if="isExpanded === listIndex" class="description">
                        <Txt v-html="item.description" />
                    </div>
                </Expand>
            </li>
        </ul>
    </ClientOnly>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Clickable from '@/components/utility/clickable';

const Expand = defineAsyncComponent(() => import('@/components/animation/expand'));

export default {
    name: 'CourseTemplateAccordion',
    components: { Txt, Icon, Expand, Clickable },
    inheritAttrs: false,
    props: {
        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }, { name: 'description', type: 'richText' }] },
        type: { type: String, default: 'accordion', enum: ['accordion', 'number'] },
    },
    data() {
        return {
            isExpanded: {}
        };
    },
};
</script>

<style lang="less" scoped>
ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: var(--cloud);
    li {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--smoke);
        button {
            width: 100%;
            padding: 24px 32px;
            display: flex;
            align-items: center;
            > .txt-component.heading {
                flex: 1;
                color: var(--navy);
            }
        }
        .number {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: var(--primary);
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
        }
        .description .txt-component {
            padding: 0 32px 24px;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
</style>
