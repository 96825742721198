<template>
    <div class="screens-marquee section-wrapper" :class="viewport.is">
        <ClientOnly>
            <section>
                <div class="fade left" />
                <div class="fade right" />
                <Marquee :count="10" :speed="30">
                    <div class="logos">
                        <Wiise />
                        <HotTopic />
                        <Choice />
                        <Volt />
                        <Convertkit />
                        <Userlane />
                        <Camplify />
                    </div>
                </Marquee>
                <div class="rating">
                    <G2Rating />
                    <Txt size="xs" heading>
                        4.7 Rating
                    </Txt>
                </div>
            </section>
        </ClientOnly>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Marquee from '@/components/animation/marquee';

import Wiise from '@/assets/website/logos/wiise.svg';
import HotTopic from '@/assets/website/logos/hot-topic.svg';
import Choice from '@/assets/website/logos/choice.svg';
import Volt from '@/assets/website/logos/volt.svg';
import Convertkit from '@/assets/website/logos/convertkit.svg';
import Userlane from '@/assets/website/logos/userlane.svg';
import Camplify from '@/assets/website/logos/camplify.svg';
import G2Rating from '@/assets/website/logos/g2-rating.svg';

export default {
    name: 'CustomersMarquee',
    components: { Txt, Marquee, Wiise, HotTopic, Choice, Userlane, Convertkit, Volt, Camplify, G2Rating },
    inheritAttrs: false,
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.screens-marquee {
    padding: 160px 0;
    section {
        position: relative;
        width: 100%;
        max-width: unset;
        flex-direction: column;
        justify-content: center;
        gap: 48px;
        .marquee {
            flex: 1;
        }
        .fade {
            position: absolute;
            top: -32px;
            width: 160px;
            height: 96px;
            z-index: 1;
            &.left {
                left: 0;
                background: linear-gradient(to right, #FFF, rgba(255, 255, 255, 0));
            }
            &.right {
                right: 0;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
            }
        }
        .logos {
            display: flex;
            gap: 64px;
            margin-right: 64px;
            svg {
                height: 32px;
                width: auto;
                max-width: 160px;
                fill: var(--navy);
            }
        }
        .rating {
            display: flex;
            align-items: center;
            gap: 16px;
            svg {
                height: 48px;
                width: auto;
            }
        }
    }
    &.tablet {
        padding: 96px 0;
        .rating {
            flex-direction: column;
        }
    }
}
</style>
