<template>
    <div
        class="split-section section-wrapper"
        :class="viewport.is"
    >
        <section>
            <div class="split-content">
                <GradientText size="xl" heading>
                    {{ title }}
                </GradientText>
                <Txt v-if="description" size="l">
                    {{ description }}
                </Txt>
            </div>
            <div class="split">
                <template
                    v-for="listItem, listIndex in list"
                    :key="listIndex"
                >
                    <div
                        class="split-item"
                    >
                        <NuxtImg
                            v-if="listItem.icon"
                            :src="listItem.icon"
                            provider="builderio"
                            width="56"
                            height="56"
                            sizes="xl:10vw lg:10vw md:10vw sm:10vw xs:10vw"
                            loading="lazy"
                            role="presentation"
                            alt=""
                        />
                        <Txt
                            tag="h3"
                            size="m" heading
                        >
                            {{ listItem.title }}
                        </Txt>
                        <Txt size="m">
                            {{ listItem.description }}
                        </Txt>
                        <NuxtImg
                            v-if="listItem.image"
                            :src="listItem.image"
                            :alt="listItem.imageAlt"
                            provider="builderio"
                            width="320"
                            height="500"
                            sizes="xl:50vw lg:50vw md:50vw sm:50vw xs:50vw"
                            loading="lazy"
                            class="split-image"
                        />
                        <div class="split-actions">
                            <Button
                                v-for="button, buttonIndex in listItem.buttons"
                                :key="buttonIndex"
                                variant="create"
                                size="xxs" heading
                                :href="button.href"
                                :data-placement="buttonPlacement"
                                @click="onCTAClick($event, button.customAction)"
                            >
                                {{ button.title }}
                            </Button>
                        </div>
                    </div>
                    <hr v-if="listIndex < list.length - 1" />
                </template>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'SplitSection',
    components: { Txt, Button, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Transform it', cmsType: 'longText' },
        description: { type: String, default: 'Turn any topic into a ridiculously good-looking course. In no time.' },
        buttonPlacement: { type: String, default: '' },

        list: {
            type: Array,
            default: null,
            subFields: [
                { name: 'title', type: 'string' },
                { name: 'description', type: 'longText' },
                { name: 'icon', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'imageAlt', type: 'string' },
                { name: 'buttons', type: 'list', subFields: [
                    { name: 'title', type: 'string' },
                    { name: 'href', type: 'string' },
                    { name: 'customAction', type: 'string', enum: ['', 'transform', 'transform-video', 'generate', 'generate-video', 'enquiry', 'headless-video'] }
                ] }
            ]
        }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event, customAction) {
            if (customAction) return this.$bus.emit(`cms:${customAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.split-section {
    position: relative;
    padding: 80px;
    background-color: var(--cloud);
    border-bottom: 1px solid var(--mercury);
    section {
        align-items: center;
        text-align: center;
        gap: 64px;
        > .txt-component { max-width: 600px; }
    }
    .split-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }
    hr {
        margin: 0;
        width: 100%;
        border: 1px solid var(--smoke);
    }
    .split {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 0 1fr;
        gap: 24px;
        .split-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            gap: 16px;
            padding: 0 48px;
            .txt-component.heading { color: var(--primary); }
            .split-image {
                width: 100%;
                border-radius: 12px;
                margin: 56px auto;
            }
            .split-actions {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 24px;
                .cobutton {
                    padding: 12px 20px;
                }
            }
        }
        hr {
            margin: 0;
            height: 100%;
            border: 1px solid var(--smoke);
            width: 0;
        }
    }
    &.tablet {
        padding: 48px;
        .split {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }
    &.mobile {
        .split {
            grid-template-columns: 1fr;
        }
    }
}
</style>
