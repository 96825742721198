<template>
    <div
        class="hero-section section-wrapper"
        :class="[viewport.is, background, layout]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <div class="hero-grid">
                <div class="hero-grid-content">
                    <GradientText
                        tag="h1"
                        :size="titleSize" heading
                    >
                        {{ title }}
                    </GradientText>
                    <Txt :size="descriptionSize">
                        {{ description }}
                    </Txt>
                    <Button
                        v-if="buttonText || buttonCustomAction || buttonHref"
                        variant="create"
                        size="m" heading
                        :href="buttonCustomAction ? undefined : buttonHref"
                        @click="onCTAClick"
                    >
                        {{ buttonText }}
                    </Button>
                    <ul v-if="list && list.length">
                        <li v-for="listItem, listIndex in list" :key="listIndex">
                            <Icon name="correct" right />
                            {{ listItem.title }}
                        </li>
                    </ul>
                </div>
                <div class="hero-grid-image">
                    <NuxtImg
                        v-if="image"
                        :src="viewport.is.mobile ? (imageMobile || image) : image"
                        :alt="imageAlt"
                        provider="builderio"
                        width="2000"
                        height="1000"
                        sizes="xl:150vw lg:150vw md:150vw sm:150vw xs:150vw"
                    />
                    <video
                        v-else-if="video"
                        :src="video"
                        muted
                        autoplay
                        loop
                        playsinline
                        width="2000"
                        height="1000"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { buttonCustomAction, background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'HeroSection',
    components: { Txt, Icon, Button, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Transform it', cmsType: 'longText' },
        titleSize: { type: String, default: 'xxl', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        emoji:  { type: String, default: '' },
        description: { type: String, default: 'Turn any topic into a ridiculously good-looking course. In no time.' },
        descriptionSize: { type: String, default: 'l', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },

        image: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
        imageMobile: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
        imageAlt: { type: String, default: 'A Coassemble course and examples of documents you could convert them from' },

        video: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['mp4'] },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }] },

        buttonText: { type: String, default: 'Give it a go' },
        buttonHref: { type: String, default: '/build' },
        buttonCustomAction,

        layout: { type: String, default: 'left', enum: ['left', 'right', 'stacked'] },
        background
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.hero-section {
    padding: 0;
    margin: 24px;
    border-radius: 12px;
    .txt-component.heading { display: inline; }
    ul { color: var(--navy); }

    .hero-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 96px;
        .hero-grid-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 32px;
            padding: 80px 0;
            .txt-component.s { color: var(--anchor); }
            .cobutton {
                padding: 18px 40px;
                margin: 12px 0;
            }
        }
        .hero-grid-image {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            border-radius: 16px;
        }
    }

    &.right {
        .hero-grid {
            grid-template-columns: 1fr 1fr;
            .hero-grid-content { order: 2; }
            .hero-grid-image { order: 1; }
        }
    }
    &.stacked {
        .hero-grid {
            grid-template-columns: 1fr;
            text-align: center;
            gap: 24px;
            .hero-grid-content {
                order: 1;
                gap: 24px;
                padding: 80px;
                align-items: center;
                .txt-component.heading { max-width: 900px; }
                .txt-component.body { max-width: 700px; }
            }
            .hero-grid-image { order: 2; }
        }
    }
    &.lg {
        .section-grid {
            gap: 32px;
        }
    }
    &.tablet {
        padding: 24px;
        padding-bottom: 0;
        margin: 0;
        border-radius: 0;
        .hero-grid {
            grid-template-columns: 1fr;
            gap: 32px;
            .hero-grid-content {
                padding: 40px 0;
            }
            .hero-grid-image {
                padding: 0;
                flex: 0 0 100%;
            }
        }
    }
}
</style>
