<template>
    <div :id="_.uid" class="resource-data-points" :class="viewport.is">
        <Txt
            v-if="title"
            tag="h3"
            size="m" heading
        >
            {{ title }}
        </Txt>
        <div class="data">
            <div
                v-for="item, listIndex in list"
                :key="listIndex"
            >
                <GradientText size="xxl" heading>
                    {{ item.stat }}
                </GradientText>
                <ClientOnly>
                    <Txt v-html="item.description" />
                </ClientOnly>
            </div>
        </div>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'ResourceDataPoints',
    components: { Txt, GradientText },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: '' },
        list: { type: Array, default: null, subFields: [{ name: 'stat', type: 'string' }, { name: 'description', type: 'richText' }] },
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
        this.$bus.emit('resource:anchor', { id: this._.uid, title: this.title });
    },
};
</script>

<style lang="less">
.resource-data-points {
    > .txt-component.heading {
        margin: 0;
        margin-bottom: 24px;
    }
    .data {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px;
        .txt-component.heading {
            margin-bottom: 16px;
        }
    }
    &.mobile {
        .data {
            grid-template-columns: 1fr;
            gap: 16px;
        }
    }
}
</style>
