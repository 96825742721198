<template>
    <div class="faq section-wrapper" :class="viewport.is">
        <section>
            <Txt size="l" heading>
                FAQs
            </Txt>
            <ClientOnly>
                <ul>
                    <li v-for="item, listIndex in list" :key="listIndex">
                        <Clickable
                            @click="listIndex === isExpanded ? isExpanded = null : isExpanded = listIndex"
                        >
                            <Txt size="s" heading>
                                {{ item.question }}
                            </Txt>
                            <Icon :name="isExpanded === listIndex ? 'minus' : 'plus'" />
                        </Clickable>
                        <Expand>
                            <div v-if="isExpanded === listIndex" class="answer">
                                <Txt>
                                    {{ item.answer }}
                                </Txt>
                            </div>
                        </Expand>
                    </li>
                </ul>
            </ClientOnly>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { defineAsyncComponent } from 'vue';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Clickable from '@/components/utility/clickable';

const Expand = defineAsyncComponent(() => import('@/components/animation/expand'));

export default {
    name: 'FAQ',
    components: { Txt, Icon, Expand, Clickable },
    inheritAttrs: false,
    props: {
        list: { type: Array, default: null, subFields: [{ name: 'question', type: 'string' }, { name: 'answer', type: 'string' }] },
    },
    data() {
        return {
            isExpanded: {},
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.faq {
    padding: 120px 32px;
    section {
        max-width: var(--max-width);
        gap: 24px;
        .txt-component.heading { color: var(--navy); }
        ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            li {
                flex: 1;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid var(--smoke);
                button {
                    padding: 24px 0;
                    display: flex;
                    justify-content: space-between;
                }
                .answer .txt-component {
                    padding-bottom: 24px;
                }
            }
        }
    }
    &.tablet {
        padding: 48px;
    }
}
</style>
