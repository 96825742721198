<template>
    <div class="testimonial section-wrapper" :class="viewport.is">
        <section>
            <Txt :size="viewport.is.tablet ? 32 : 40" heading>
                “{{ quote }}“
            </Txt>
            <ClientOnly v-if="logo">
                <Component :is="logoComponent" />
            </ClientOnly>
            <Txt size="s">
                {{ source }}
            </Txt>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';

import HotTopic from '@/assets/website/logos/hot-topic.svg';
import Userlane from '@/assets/website/logos/userlane.svg';
import Volt from '@/assets/website/logos/volt.svg';
import Hopper from '@/assets/website/logos/hopper.svg';

export default {
    name: 'Testimonial',
    components: { Txt },
    inheritAttrs: false,
    props: {
        quote: { type: String, default: 'Coassemble’s templates are an easy and sure way to elevate just about any type of content.' },
        source: { type: String, default: 'Julia Sullivan, HR Manager' },
        logo: { type: String, default: 'hopper', enum: ['hopper', 'volt', 'userlane', 'hottopic'] }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    computed: {
        logoComponent() {
            return {
                hottopic: HotTopic,
                userlane: Userlane,
                volt: Volt,
                hopper: Hopper
            }[this.logo];
        },
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.testimonial {
    padding: 120px 32px;
    section {
        max-width: 700px;
        gap: 24px;
        .txt-component.heading { color: var(--primary); }
        svg {
            margin-top: 8px;
            fill: var(--navy);
            max-width: 108px;
        }
    }
    &.tablet {
        padding: 48px;
    }
}
</style>
