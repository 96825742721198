<template>
    <div
        :id="id"
        class="two-col-section section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <div class="two-col-grid" :class="layout">
                <div class="two-col-grid-content">
                    <Txt
                        tag="h2"
                        size="l" heading
                    >
                        {{ title }}
                    </Txt>
                    <Txt v-if="description" size="l">
                        {{ description }}
                    </Txt>
                    <ul v-if="list && list.length">
                        <li v-for="listItem, listIndex in list" :key="listIndex">
                            <Icon name="correct" right />
                            {{ listItem.title }}
                        </li>
                    </ul>
                    <Button
                        v-if="buttonText || buttonCustomAction || buttonHref"
                        variant="create"
                        size="xxs" heading
                        :href="buttonCustomAction ? undefined : buttonHref"
                        :data-placement="buttonPlacement"
                        @click="onCTAClick"
                    >
                        {{ buttonText }}
                    </Button>
                </div>
                <div class="two-col-grid-image">
                    <NuxtImg
                        v-if="image"
                        :src="image"
                        :alt="imageAlt"
                        provider="builderio"
                        width="2000"
                        height="1000"
                        sizes="xl:100vw lg:100vw md:100vw sm:100vw xs:100vw"
                        loading="lazy"
                    />
                    <video
                        v-else-if="video"
                        :src="video"
                        :alt="videoAlt"
                        playsinline
                        autoplay
                        muted
                        loop
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { buttonCustomAction, background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Button from '@/components/utility/button';

export default {
    name: 'TwoColumnSection',
    components: { Txt, Icon, Button },
    inheritAttrs: false,
    props: {
        id: { type: String, default: null },
        title:  { type: String, default: 'Take your content, make it WAY better', cmsType: 'longText' },
        description: { type: String, default: 'With a few clicks, you can transform your content into beautiful courses that will look like a professional created it, without the cost.' },

        image: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
        imageAlt: { type: String, default: 'A Coassemble course and examples of documents you could convert them from' },

        video: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['mp4'] },
        videoAlt: { type: String, default: 'A Coassemble course and examples of documents you could convert them from' },

        list: { type: Array, default: null, subFields: [{ name: 'title', type: 'string' }] },

        buttonText: { type: String, default: 'Give it a go' },
        buttonHref: { type: String, default: '/build' },
        buttonPlacement: { type: String, default: '' },
        buttonCustomAction,

        background,
        layout: { type: String, default: 'left', enum: ['left', 'right'] }
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.two-col-section {
    padding: 48px 0;
    .txt-component.heading { color: var(--navy); }

    .two-col-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 96px;
        &.right {
            .two-col-grid-content {
                order: 2;
            }
            .two-col-grid-image {
                order: 1;
            }
        }
        .two-col-grid-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 48px 0;
            gap: 24px;
            .txt-component.s { color: var(--anchor); }
            .cobutton {
                padding: 12px 24px;
            }
        }
        .two-col-grid-image {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            background-color: var(--cloud);
            border-radius: 16px;
            overflow: hidden;
            img, video {
                height: 100%;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
    &.tablet {
        padding: 24px 0;
        .two-col-grid {
            grid-template-columns: 1fr;
            gap: 32px;
            .two-col-grid-image {
                order: 1;
            }
            .two-col-grid-content {
                order: 2;
                padding: 16px 24px;
            }
        }
    }
}
</style>
