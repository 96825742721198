<template>
    <Head>
        <Title>{{ title }}</Title>
        <Meta name="description" :content="description" />
        <Meta property="og:title" :content="title" />
        <Meta property="og:description" :content="description" />
        <Meta property="og:image" :content="image" />
        <Meta name="twitter:title" :content="title" />
        <Meta name="twitter:description" :content="description" />
        <Meta name="twitter:image" :content="image" />
        <Meta v-if="$global.isProduction" name="robots" content="index, follow" />
        <Meta v-else name="robots" content="noindex, nofollow" />
        <Link rel="canonical" :href="`https://coassemble.com${$route.path}`" />
        <Link rel="icon" type="image/x-icon" href="https://frontend.coassemble.com/favicon.ico" />
        <Link rel="icon" type="image/png" sizes="32x32" href="https://frontend.coassemble.com/favicon-32x32.png" />
        <Link rel="icon" type="image/png" sizes="16x16" href="https://frontend.coassemble.com/favicon-16x16.png" />
        <Meta name="google-site-verification" content="u7ORYWxZQZ5MKzVTbFADKT7yuymXRGZtV4jHA8Ey5xg" />
        <Meta name="google-site-verification" content="g_o8HmLRUAgLG-70sC45OxPZaoBKcJ2Iutc5jWNfkUE" />
    </Head>
    <main
        :class="viewport.is"
        class="cms"
    >
        <Header :templates="templates" />
        <template v-if="isLoaded && model === 'page'">
            <slot
                :content="builderContent"
                :model="model"
                :api-key="apiKey"
                :custom-components="customComponents"
                :resources="resources"
                :categories="categories"
                :topics="topics"
                :content-types="contentTypes"
                :templates="templates"
            />
            <Content
                :model="model"
                :content="builderContent"
                :api-key="apiKey"
                :custom-components="customComponents"
            />
        </template>
        <slot
            v-else-if="isLoaded"
            :content="builderContent"
            :model="model"
            :api-key="apiKey"
            :custom-components="customComponents"
        />
        <NotFound v-else-if="!isLoading" />
        <Footer />
    </main>
</template>

<script>
import { computed } from 'vue';
import { groupBy } from '@/helpers/utilities';
import { useResponsive } from '@/store/responsive';
import { fetchEntries, fetchOneEntry, Content } from '@builder.io/sdk-vue';

import Header from '@/components/author/header';
import Footer from '@/components/author/footer';
import NotFound from '@/components/author/cms/not-found';

import Accolades from '@/components/author/cms/accolades';
import Accordion from '@/components/author/cms/accordion';
import CallToAction from '@/components/author/cms/call-to-action';
import CallToActionCard from '@/components/author/cms/call-to-action.card';
import CardGridSection from '@/components/author/cms/section.card-grid';
import CustomerLogos from '@/components/author/cms/logos.customer';
import CustomMarquee from '@/components/author/cms/marquee.custom';
import CustomerMarquee from '@/components/author/cms/marquee.customer';
import FAQ from '@/components/author/cms/faqs';
import FormEnquiry from '@/components/author/cms/form.enquiry';
import G2 from '@/components/author/cms/g2';
import ContentSection from '@/components/author/cms/section.contents';
import GridSection from '@/components/author/cms/section.grid';
import Heading from '@/components/author/cms/heading';
import HeroSection from '@/components/author/cms/section.hero';
import RichText from '@/components/author/cms/rich-text';
import ScormLogos from '@/components/author/cms/logos.scorm';
import ScreensMarquee from '@/components/author/cms/marquee.screens';
import Stats from '@/components/author/cms/stats';
import StepSection from '@/components/author/cms/section.step';
import SplitSection from '@/components/author/cms/section.split';
import TabsSection from '@/components/author/cms/section.tabs';
import TabContentSection from '@/components/author/cms/section.tab-content';
import Testimonial from '@/components/author/cms/testimonial';
import TestimonialCarousel from '@/components/author/cms/testimonial.carousel';
import TwoColumnSection from '@/components/author/cms/section.twocolumn';
import ThreeColumnSection from '@/components/author/cms/section.threecolumn';

import TemplateImage from '@/components/author/cms/templates/image';
import TemplateSection from '@/components/author/cms/templates/section';
import TemplateAccordion from '@/components/author/cms/templates/accordion';

import ResourceImage from '@/components/author/cms/resources/image';
import ResourceVideo from '@/components/author/cms/resources/video';
import ResourceRelated from '@/components/author/cms/resources/related';
import ResourceDivider from '@/components/author/cms/resources/divider';
import ResourceCallout from '@/components/author/cms/resources/callout';
import ResourceParagraph from '@/components/author/cms/resources/paragraph';
import ResourceAccordion from '@/components/author/cms/resources/accordion';
import ResourceDataPoints from '@/components/author/cms/resources/data-points';
import ResourceBulletedList from '@/components/author/cms/resources/bulleted-list';
import ResourceCallToAction from '@/components/author/cms/resources/call-to-action';

const getPropType = prop => {
    let type = prop.type;
    if (Array.isArray(type)) type = type[0];
    const typeString = type
        && type.prototype
        && type.prototype.constructor
        && type.prototype.constructor.name.toLowerCase()
        || 'string';

    if (typeString === 'array') return 'list';
    return typeString;
};

const mapComponents = (components) => {
    return components.map(component => {
        return {
            name: component.name,
            component,
            canHaveChildren: component.canHaveChildren || false,
            inputs: component.props
                ? Object.keys(component.props).map((name) => {
                    const prop = component.props[name];
                    return {
                        name,
                        type: prop.cmsType || getPropType(prop),
                        defaultValue: (typeof prop.default === 'function' ? prop.default() : prop.default),
                        enum: prop.enum || null,
                        subFields: prop.subFields || null,
                        allowedFileTypes: prop.allowedFileTypes || null
                    };
                })
                : []
        };
    });
};

const TEMPLATES_ORDER = ['Policies & Procedures', 'Onboarding', 'Customer Training', 'Product Training'];

const fetchCMSData = async (context) => {
    let path = context._route.path;
    const model = context._route.meta.model || 'page';

    let query = null;
    if (model === 'course-template') {
        query = { data: { slug: path.replace('/templates/', '') } };
    }
    if (model === 'resource') {
        query = { data: { slug: path.replace('/resource/', '') } };
    }

    // Fetch resources in asyncData so they prerender
    let resources, categories, topics, contentTypes;
    if (path.includes('/resources')) {
        resources = await fetchEntries({
            model: 'resource',
            apiKey: context.$config.public.builderKey,
            options: {
                omit: 'data.blocks',
                includeUnpublished: context.$config.public.lmsURL !== 'https://app.coassemble.com'
            },
            limit: 10,
            offset: 0
        });
        resources = resources.map(resource => resource.data);
        categories = await fetchEntries({
            model: 'resource-category',
            apiKey: context.$config.public.builderKey
        });
        categories = categories.map(c => c.data);
        topics = await fetchEntries({
            model: 'resource-topic',
            apiKey: context.$config.public.builderKey
        });
        topics = topics.map(t => t.data);
        contentTypes = await fetchEntries({
            model: 'resource-content-type',
            apiKey: context.$config.public.builderKey
        });
        contentTypes = contentTypes.map(t => t.data);
    }

    // Get templates to display in the header menu
    let allTemplates = await fetchEntries({
        model: 'course-template',
        apiKey: context.$config.public.builderKey,
        options: { omit: 'data.blocks' }
    });
    allTemplates = allTemplates.map(template => {
        template.data.categoryName = template.data.category.value && template.data.category.value.data.title;
        return template.data;
    });
    allTemplates = allTemplates.sort((a, b) => TEMPLATES_ORDER.indexOf(a.categoryName) - TEMPLATES_ORDER.indexOf(b.categoryName));
    const templates = groupBy(allTemplates, 'categoryName');

    return {
        builderContent: await fetchOneEntry({
            model,
            apiKey: context.$config.public.builderKey,
            userAttributes: model === 'page' ? { urlPath: path } : undefined,
            query,
            options: {
                includeUnpublished: context.$config.public.lmsURL !== 'https://app.coassemble.com'
            },
        }),
        templates,
        resources,
        categories,
        topics,
        contentTypes
    };
};

export default defineNuxtComponent({
    name: 'CMSLayout',
    components: { Content, Header, Footer, NotFound },
    inject: ['$global'],
    async asyncData(context) {
        if (Object.keys(context._route.query).length > 0) return;
        return await fetchCMSData(context);
    },
    data() {
        return {
            model: this.$route.meta.model || 'page',
            apiKey: this.$config.public.builderKey,

            builderContent: null,
            templates: null,
            resources: null,
            categories: null,
            topics: null,
            contentTypes: null,
            isLoading: false,

            viewport: { is: {} }
        };
    },
    async created() {
        if (Object.keys(this.$route.query).length > 0) {
            this.isLoading = true;
            const data = await fetchCMSData({ 
                _route: this.$route, 
                $config: this.$config 
            });
            this.isLoading = false;
            this.builderContent = data.builderContent;
            this.templates = data.templates;
            this.resources = data.resources;
            this.categories = data.categories;
            this.topics = data.topics;
            this.contentTypes = data.contentTypes;
        }
    },
    provide() {
        return {
            apiKey: computed(() => this.apiKey),
            content: computed(() => this.builderContent),
            customComponents: computed(() => this.customComponents),
            templates: computed(() => this.templates),
            
            resources: computed(() => this.resources),
            categories: computed(() => this.categories),
            topics: computed(() => this.topics),
            contentTypes: computed(() => this.contentTypes)
        };
    },
    computed: {
        isLoaded() {
            return this.builderContent && !this.isLoading;
        },
        contentData() {
            return this.builderContent?.data;
        },
        title() {
            return this.builderContent?.data?.seoTitle
                || this.builderContent?.data?.title
                || 'Coassemble | Your first online training platform';
        },
        description() {
            return this.builderContent?.data?.seoDescription
                || this.builderContent?.data?.description
                || 'Create and deliver beautiful online training with a platform trusted by entrepreneurs, businesses and training organizations worldwide.';
        },
        image() {
            return this.builderContent?.data?.image
                || 'https://frontend.coassemble.com/coassemble-opengraph.jpg';
        },
        customComponents() {
            if (this.model === 'course-template') {
                return mapComponents([
                    TemplateImage,
                    TemplateSection,
                    TemplateAccordion
                ]);
            }
            if (this.model === 'resource') {
                return mapComponents([
                    ResourceImage,
                    ResourceVideo,
                    ResourceCallToAction,
                    ResourceRelated,
                    ResourceDivider,
                    ResourceCallout,
                    ResourceParagraph,
                    ResourceAccordion,
                    ResourceDataPoints,
                    ResourceBulletedList
                ]);
            }
            return mapComponents([
                Accolades,
                Accordion,
                CallToAction,
                CallToActionCard,
                CardGridSection,
                CustomerLogos,
                CustomMarquee,
                CustomerMarquee,
                FAQ,
                FormEnquiry,
                G2,
                ContentSection,
                GridSection,
                Heading,
                HeroSection,
                RichText,
                ScormLogos,
                ScreensMarquee,
                SplitSection,
                Stats,
                StepSection,
                TabsSection,
                TabContentSection,
                Testimonial,
                TestimonialCarousel,
                TwoColumnSection,
                ThreeColumnSection
            ]);
        }
    },
    mounted() {
        this.viewport = useResponsive();
        this.$global.get();
        this.$bus.on('cms:scroll-to-top', this.scrollToTop);
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
});
</script>

<style lang="less">
@import '~/less/global.cms.less';
</style>
