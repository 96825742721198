<template>
    <div class="resource-cta">
        <Button
            v-if="buttonText || buttonHref || buttonCustomAction"
            variant="create"
            size="s" heading
            :href="buttonCustomAction ? undefined : buttonHref"
            :data-placement="buttonPlacement"
            @click="onCTAClick"
        >
            {{ buttonText }}
        </Button>
    </div>
</template>

<script>
import { buttonCustomAction } from '@/components/author/cms/constants';

import Button from '@/components/utility/button';

export default {
    name: 'ResourceCallToAction',
    components: { Button },
    inheritAttrs: false,
    props: {
        buttonText: { type: String, default: 'Create course' },
        buttonHref: { type: String, default: '/build' },
        buttonPlacement: { type: String, default: 'cta' },
        buttonCustomAction
    },
    methods: {
        onCTAClick(event) {
            if (this.buttonCustomAction) return this.$bus.emit(`cms:${this.buttonCustomAction}`, event);
        }
    }
};
</script>

<style lang="less" scoped>
.resource-cta {
    display: flex;
    justify-content: center;
    .cobutton {
        padding: 14px 32px;
    }
}
</style>
