<template>
    <figure>
        <video
            v-if="video"
            :src="video"
            :alt="videoAlt || caption"
            controls
        />
        <figcaption v-if="caption">
            <Txt size="xs">
                {{ caption }}    
            </Txt>
        </figcaption>
    </figure>
</template>

<script>
import Txt from '@/components/form/txt';

export default {
    name: 'ResourceVideo',
    components: { Txt },
    inheritAttrs: false,
    props: {
        video: { type: String, default: null, cmsType: 'file', allowedFileTypes: ['mov', 'mp4', 'webm'] },
        videoAlt: { type: String, default: '' },
        caption: { type: String, default: '' }
    }
};
</script>

<style lang="less" scoped>
figure {
    video {
        max-width: 100%;
        border-radius: 12px;
    }
    .txt-component.body {
        color: var(--anchor);
        margin-top: 16px;
    }
}
</style>
