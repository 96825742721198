<template>
    <div
        class="testimonial-carousel section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})`, '--background': `var(--${background})` }"
    >
        <section>
            <div class="heading">
                <GradientText
                    :variant="gradientVariant"
                    :size="viewport.is.tablet ? 28 : 40" heading
                >
                    {{ title }}
                </GradientText>
            </div>
            <ClientOnly>
                <Carousel
                    ref="carousel"
                    :length="testimonials.length"
                    :slide-width="viewport.is.mobile ? 327 : viewport.is.tablet ? element.width - 64 : slideWidth"
                    infinite
                    @select="onSlideSelect"
                >
                    <div
                        v-for="testimonial, index in testimonials"
                        :key="index"
                        class="testimonial-card"
                        @click="$refs.carousel.goToNextSlide()"
                    >
                        <div class="card-content">
                            <div v-if="testimonial.logo" class="logo">
                                <NuxtImg
                                    :src="testimonial.logo"
                                    :alt="testimonial.company"
                                    provider="builderio"
                                    width="120"
                                    height="40"
                                    loading="lazy"
                                />
                            </div>
                            <Txt size="m" class="quote">
                                {{ testimonial.quote }}
                            </Txt>
                            <div class="author">
                                <div v-if="testimonial.avatar" class="avatar">
                                    <NuxtImg
                                        :src="testimonial.avatar"
                                        :alt="testimonial.name"
                                        provider="builderio"
                                        width="48"
                                        height="48"
                                        loading="lazy"
                                    />
                                </div>
                                <div class="details">
                                    <Txt size="xxs" heading>
                                        {{ testimonial.name }}
                                    </Txt>
                                    <Txt size="s" class="anchor">
                                        {{ testimonial.title }}
                                    </Txt>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                <div class="pagination">
                    <div
                        v-for="(_, index) in testimonials.length"
                        :key="index"
                        :class="{ active: index === selectedIndex }"
                        class="pagination-dot"
                    />
                </div>
            </ClientOnly>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';
import Carousel from '@/components/interactive/carousel';
import GradientText from '@/components/animation/gradient-text';

background.default = 'white';

export default {
    name: 'TestimonialCarousel',
    components: { Txt, Carousel, GradientText },
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: 'But don\'t listen to us... Hear what our customers have to say!'
        },
        gradientVariant: {
            type: String,
            default: 'blue',
            enum: ['blue', 'lightblue']
        },
        testimonials: {
            type: Array,
            default: () => [],
            subFields: [
                { name: 'logo', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'company', type: 'string' },
                { name: 'quote', type: 'string' },
                { name: 'avatar', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp'] },
                { name: 'name', type: 'string' },
                { name: 'title', type: 'string' }
            ]
        },
        background
    },
    data() {
        return {
            viewport: { is: {} },
            element: { is: {} },

            autoplayInterval: null,
            slideWidth: 800,
            selectedIndex: 0
        };
    },
    mounted() {
        this.viewport = useResponsive();
        this.element = useResponsive(this, this.$el);
        this.startAutoplay();
    },
    beforeUnmount() {
        this.stopAutoplay();
    },
    methods: {
        startAutoplay() {
            this.autoplayInterval = setInterval(() => {
                if (this.$refs.carousel) {
                    this.$refs.carousel.goToNextSlide();
                }
            }, 8000);
        },
        stopAutoplay() {
            if (this.autoplayInterval) {
                clearInterval(this.autoplayInterval);
            }
        },
        onSlideSelect(index) {
            // Reset autoplay timer when manually navigating
            this.stopAutoplay();
            this.startAutoplay();
            this.selectedIndex = index;
        }
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.testimonial-carousel {
    padding: 160px 0;
    section {
        position: relative;
        max-width: 1200px;
        gap: 40px;
        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 151px;
            height: 100%;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(to right, var(--background), transparent);
        }
        &::after {
            right: 0;
            left: auto;
            background: linear-gradient(to right, transparent, var(--background));
        }
        .heading {
            text-align: center;
            max-width: 700px;
            margin: 0 auto;
            .highlight {
                color: var(--primary);
            }
        }
        :deep(.carousel-wrapper) {
            display: flex;
            justify-content: center;
        }
        .testimonial-card {
            padding: 0 16px;
            cursor: pointer;
            
            .card-content {
                background: white;
                border-radius: 16px;
                padding: 64px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 24px;
                box-shadow: var(--shadow-lg);

                .logo {
                    height: 40px;
                    img {
                        height: 100%;
                        width: auto;
                        object-fit: contain;
                    }
                }
                .quote {
                    flex: 1;
                }
                .author {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    text-align: left;
                    .avatar {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        overflow: hidden;
                        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                    }
                }
            }
        }
    }
   .pagination {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      margin-bottom: 24px;
      .pagination-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgba(var(--coal-rgb), 0.2);
            &.active { background-color: var(--navy); }
      }
   }
    &.tablet {
        padding: 40px 0;
        section {
            &::before, &::after {
                display: none;
            }
        }
    }
}
</style> 
