<template>
    <header :class="viewport.is" class="header">
        <nav>
            <a href="/">
                <DefaultSquareLogo v-if="viewport.is.mobile" class="logo" />
                <DefaultLogo v-else class="logo" />
            </a>
            <ul v-if="isShowingMobileNav || viewport.is.desktop" class="links">
                <li v-if="viewport.is.tablet || !templates">
                    <Button
                        variant="basic"
                        colour="navy"
                        size="xxs" heading
                        href="/course-templates"
                    >
                        Templates
                    </Button>
                </li>
                <ClientOnly v-else>
                    <Dropdown
                        variant="basic"
                        colour="navy"
                        size="xxs" heading
                        align="center"
                        hoverable
                        :chevron="false"
                        :type="viewport.is.tablet ? 'inline' : 'dropdown'"
                        class="templates"
                    >
                        Templates
                        <template #menu>
                            <section
                                v-for="categoryTemplates, category in templates"
                                :id="category.replace(/\s+/g, '-').toLowerCase()"
                                :key="category"
                            >
                                <Txt size="marker" class="anchor">
                                    {{ category }}
                                </Txt>
                                <Button
                                    v-for="template, templateIndex in categoryTemplates"
                                    :key="templateIndex"
                                    variant="basic"
                                    colour="navy"
                                    size="xxs" heading
                                    :href="`/templates/${template.slug}`"
                                    class="popup-button"
                                >
                                    {{ template.title }}
                                </Button>
                            </section>
                        </template>
                    </Dropdown>
                </ClientOnly>
                <li>
                    <Button
                        variant="basic"
                        colour="navy"
                        size="xxs" heading
                        href="/pricing"
                        data-placement="header"
                        data-context="header"
                    >
                        Pricing
                    </Button>
                </li>
            </ul>
        </nav>
        <div class="actions">
            <Button
                v-if="$global.user"
                :href="$global.client ? '/courses' : `${$http.lmsUrl}/security/client`"
                variant="create"
                :size="viewport.is.mobile ? 'xxxs' : 'xxs'" heading
                :slim="viewport.is.mobile"
            >
                Dashboard
            </Button>
            <template v-else>
                <Button
                    variant="basic"
                    colour="navy"
                    href="/signin"
                    :data-context="$track && $track.baseProperties && $track.baseProperties.page_name"
                    :size="viewport.is.mobile ? 'xxxs' : 'xxs'" heading
                >
                    Log in
                </Button>
                <Button
                    v-if="!viewport.is.mobile"
                    variant="create"
                    href="/build"
                    size="xxs" heading
                    data-placement="header"
                >
                    Create course
                </Button>
            </template>
            <ClientOnly>
                <Button
                    variant="ghost"
                    data-placement="header"
                    data-intercom-target="sales"
                    :size="viewport.is.mobile ? 'xxxs' : 'xxs'" heading
                    :slim="viewport.is.mobile"
                >
                    Contact sales
                </Button>
            </ClientOnly>
            <Button
                v-if="viewport.is.tablet"
                variant="basic"
                colour="navy"
                :icon="isShowingMobileNav ? 'cross' : 'menu'"
                :icon-label="isShowingMobileNav ? 'Collapse Nav' : 'Expand Nav'"
                @click="isShowingMobileNav = !isShowingMobileNav"
            />
        </div>
    </header>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';

const Dropdown = defineAsyncComponent(() => import('@/components/utility/dropdown'));

import DefaultLogo from '@/assets/CO_Logo_H_RGB.svg';
import DefaultSquareLogo from '@/assets/CO_Symbol_RGB.svg';

export default  {
    name: 'Header',
    components: { Txt, Button, Dropdown, DefaultLogo, DefaultSquareLogo },
    inject: ['$global'],
    props: {
        templates: { type: Object, default: null }
    },
    emits: ['build'],
    data() {
        return {
            isLoggingIn: false,
            isShowingMobileNav: false,
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
header.header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    gap: 16px;
    border-bottom: 1px solid var(--cloud);
    background-color: rgba(255, 255, 255, 0.94);
    backdrop-filter: blur(4px);
    z-index: 999;
    .logo {
        width: 220px;
        margin-right: 32px;
    }
    nav {
        display: flex;
        align-items: center;
        ul.links.links {
            padding: 0;
            flex: 1 1 auto;
            list-style-type: none;
            display: grid;
            grid-auto-flow: column;
            gap: 16px;
            li { margin: 0; }
        }
        a { display: flex; }
    }
    .actions {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        gap: 16px;
        .cobutton.ghost {
            border-radius: 40px;
            border-width: 1.5px;
        }
        .cobutton.ghost, .cobutton.create {
            padding: 10px 24px;
            height: 40px;
            &.slim {
                padding: 8px 12px;
                height: 32px;
            }
        }
    }

    &.tablet {
        ul.links.links {
            position: absolute;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 0; 
            background-color: #FFF;
            border-top: 1px solid var(--smoke);
            left: 0;
            top: 64px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            li, .dropdown {
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                border-bottom: 1px solid var(--smoke);
                .cobutton {
                    flex: 1 1 auto;
                    justify-content: space-between;
                    padding: 24px;
                }
                &:hover {
                    background-color: var(--cloud);
                }
            }
        }
        .actions {
            gap: 8px;
        }
    }
    &.mobile {
        padding: 8px;
        .logo {
            width: 48px;
            height: 48px;
            margin: 0;
        }
        .actions {
            gap: 4px;
            .cobutton.basic { padding: 8px; }
        }
    }
}
</style>

<style lang="less">
header.header {
    .dropdown .popup .popup-inner {
        width: 240px;
        .cobutton .txt-component.heading {
            font-weight: 400;
        }
    }
    .dropdown.templates {
        .popup {
            right: unset;
            left: -240px;
            z-index: 999;
            .popup-inner {
                width: auto;
                flex-direction: row;
                gap: 16px;
                text-align: left;
                padding: 16px;
                max-width: calc(100vw - 64px);
                .txt-component.marker { padding: 8px 16px; }
            }
        }
    }
    &.tablet {
        ul.links {
            .dropdown > .cobutton {
                padding: 24px;
            }
        }
    }
}
</style>
