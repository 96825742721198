<template>
    <footer :class="viewport.is">
        <div
            v-for="group, groupIndex in navGroups"
            :key="groupIndex"
        >
            <Txt
                v-if="group.text"
                size="xs" heading
            >
                {{ group.text }}
            </Txt>
            <ul>
                <li
                    v-for="link, linkIndex in group.links"
                    :key="linkIndex"
                >
                    <Button
                        variant="basic"
                        :href="link.url"
                        :colour="link.url ? 'primary' : 'anchor'"
                        :disabled="!link.url"
                        :target="link.target"
                        size="xxs" heading
                    >
                        {{ link.text }}
                    </Button>
                </li>
            </ul>
        </div>
        <div class="secondary-footer">
            <div class="socials">
                <Button
                    v-for="social, id in socials"
                    :key="id"
                    variant="basic"
                    :href="social.url"
                    :icon="`social-${id}`"
                />
            </div>
            <DefaultLogo class="logo" />
            <Txt size="xs">
                © Coassemble 2024
            </Txt>
        </div>
    </footer>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';

import DefaultLogo from '@/assets/CO_Logo_H_RGB.svg';

export default  {
    name: 'Footer',
    components: { Txt, Button, DefaultLogo },
    inject: ['$global'],
    emits: ['build'],
    data() {
        return {
            navGroups: [
                {
                    text: 'Solutions',
                    links: [
                        { text: 'Course Builder', url: '/course-builder' },
                        { text: 'Headless', url: '/headless' },
                        { text: 'Studio', url: '/studio' },
                        { text: 'Convert a document', url: '/transform' },
                        { text: 'Generate a course with AI', url: '/ai-course-generator' }
                    ]
                },
                {
                    text: 'Company',
                    links: [
                        { text: 'Resources', url: '/resources' },
                        { text: 'About Us', url: '/about' },
                        { text: 'Terms of Service', url: '/terms-of-service' },
                        { text: 'Privacy Policy', url: '/privacy-policy' },
                        { text: 'Developer Documentation', url: 'https://developers.coassemble.com' },
                        { text: 'Trust Center', url: 'https://trust.coassemble.com', target: '_blank' },
                    ]
                },
            ],
            socials: {
                facebook: {
                    name: 'Facebook',
                    url: 'https://www.facebook.com/coassemble'
                },
                linkedin: {
                    name: 'LinkedIn',
                    url: 'https://www.linkedin.com/company/coassemble'
                },
                youtube: {
                    name: 'YouTube',
                    url: 'https://www.youtube.com/channel/UCnYvXT4nH4-vBq0cMP8Yxzw/featured'
                },
                instagram: {
                    name: 'Instagram',
                    url: 'https://www.instagram.com/coassemble'
                },
                vimeo: {
                    name: 'Vimeo',
                    url: 'https://vimeo.com/user63836322'
                }
            },
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
footer {
    max-width: var(--max-width);
    margin: 0 auto;
    display: grid;
    gap: 32px;
    padding: 32px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    .txt-component.heading.xs { margin-bottom: 16px; }
    li { margin: 0; }
    .secondary-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        grid-column: span 2;
        .logo {
            width: 220px;
        }
        .socials {
            margin-bottom: 24px;
        }
    }
    &.lg, &.tablet {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .secondary-footer {
            align-items: center;
        }
    }
    &.mobile {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        .secondary-footer {
            grid-column: span 1;
        }
    }
}
</style>
