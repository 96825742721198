<template>
    <div
        class="g2 section-wrapper"
        :class="viewport.is"
    >
        <section>
            <div class="two-col-grid">
                <div class="two-col-grid-left">
                    <div>
                        <NuxtImg
                            src="/logos/g2.png"
                            alt="G2"
                            :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                            width="48"
                            height="48"
                            loading="lazy"
                        />
                        <div>
                            <Txt size="s" heading>
                                4.7 Rating (166 reviews)
                            </Txt>
                            <Rating />
                        </div>
                    </div>
                    <hr />
                    <div>
                        <NuxtImg
                            src="/logos/g2_ease.png"
                            alt="9.2 rating"
                            :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                            width="72"
                            height="72"
                            loading="lazy"
                        />
                        <Txt size="s" heading>
                            Ease of use
                        </Txt>
                    </div>
                    <div>
                        <NuxtImg
                            src="/logos/g2_quality.png"
                            alt="9.2 rating"
                            :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                            width="72"
                            height="72"
                            loading="lazy"
                        />
                        <Txt size="s" heading>
                            Quality of support
                        </Txt>
                    </div>
                    <div>
                        <NuxtImg
                            src="/logos/g2_setup.png"
                            alt="9.2 rating"
                            :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                            width="72"
                            height="72"
                            loading="lazy"
                        />
                        <Txt size="s" heading>
                            Easy to setup
                        </Txt>
                    </div>
                </div>
                <div class="two-col-grid-right">
                    <div class="quote">
                        <div class="source">
                            <span class="mark">"</span>
                            <Txt>
                                Heleena M.<br />
                                Senior Manager
                            </Txt>
                        </div>
                        <Txt>
                            This platform simplifies my work - I love how I can share, and upload so many attachments, links, videos and more in every course.
                        </Txt>
                    </div>
                    <div class="quote">
                        <div class="source">
                            <span class="mark">"</span>
                            <Txt>
                                Chloe P<br />
                                Talent Ops Lead
                            </Txt>
                        </div>
                        <Txt>
                            We chose Coassemble because of how easy it is to use - for both the learner and an administrator. The feedback from both trainers and learners has been excellent.
                        </Txt>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';

import Rating from '@/assets/website/4p5stars.svg';

export default {
    name: 'G2',
    components: { Txt, Rating },
    inheritAttrs: false,
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.g2 {
    padding: 32px 32px 80px;

    .two-col-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 72px;
        .two-col-grid-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;
            color: var(--primary);
            fill: var(--primary);
            > div {
                display: flex;
                align-items: center;
                gap: 20px;
                svg {
                    width: 96px;
                    margin-top: 8px;
                }
            }
            hr {
                width: 100%;
                border: 1px solid var(--smoke);
            }
        }
        .two-col-grid-right {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .quote {
                display: flex;
                flex-direction: column;
                background-color: var(--cloud);
                border-radius: 16px;
                padding: 40px;
                gap: 12px;
                .source {
                    display: flex;
                    align-items: center;
                    color: var(--primary);
                    gap: 12px;
                    .mark {
                        width: 44px;
                        height: 44px;
                        border-radius: 100%;
                        display: flex;
                        text-align: center;
                        background-color: #FFF;
                        font-size: 56px;
                        line-height: 80px;
                        padding: 0 4px;
                        font-style: italic;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    &.tablet {
        .two-col-grid {
            grid-template-columns: 1fr;
            gap: 32px;
            .two-col-grid-left {
                padding: 32px 24px;
            }
        }
    }
}
</style>
