<template>
    <div v-if="related" class="resource-related">
        <NuxtImg
            v-if="related.data.image"
            :src="related.data.image"
            :alt="related.data.title"
            provider="builderio"
            width="196"
            height="172"
            sizes="xl:30vw lg:30vw md:30vw sm:30vw xs:30vw"
        />
        <div class="related-resource">
            <Txt
                tag="h3"
                size="m" heading
            >
                {{ related.data.title }}
            </Txt>
            <Button
                variant="basic"
                icon="arrow-right"
                size="xs" heading
                :href="`/resource/${related.data.slug}`"
            >
                Read more
            </Button>
        </div>
    </div>
</template>

<script>
import { fetchOneEntry } from '@builder.io/sdk-vue';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';

export default {
    name: 'ResourceRelated',
    components: { Txt, Button },
    inheritAttrs: false,
    props: {
        slug: { type: String, default: '' },
    },
    data() {
        return {
            related: null
        };
    },
    watch: {
        slug: {
            immediate: true,
            async handler(slug) {
                if (!slug) return;
                this.related = await fetchOneEntry({
                    model: 'resource',
                    apiKey: this.$config.public.builderKey,
                    query: { data: { slug } }
                });
            }
        }
    },
};
</script>

<style lang="less">
.resource-related {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 24px;
    margin: 16px 0;
    border-top: 1px solid var(--steel);
    border-bottom: 1px solid var(--steel);
    img {
        border-radius: 12px;
    }
    .related-resource {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;
        gap: 8px;
        h3 {
            margin: 0;
        }
    }
}
</style>
