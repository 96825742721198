<template>
    <div class="scorm-logos section-wrapper" :class="viewport.is">
        <Txt
            tag="h2"
            size="l" heading
            align="center"
        >
            Yes, we are SCORM compatible
        </Txt>
        <Txt align="center">
            Export into any LMS
        </Txt>
        <section>
            <NuxtImg
                v-for="logo, index in logos"
                :key="index"
                :src="logo.src"
                :class="logo.class"
                :modifiers="{ auto: 'compress,format', q: '90', dpr: '2' }"
                :width="logo.width"
                :height="logo.height"
                sizes="xl:20vw lg:20vw md:20vw sm:20vw xs:20vw"
                loading="lazy"
                role="presentation"
                alt=""
            />
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';

export default {
    name: 'SCORMLogos',
    components: { Txt },
    inheritAttrs: false,
    data() {
        return {      
            logos: [
                {
                    src: '/scorm/moodle-2.png',
                    width: 160,
                    height: 110
                },
                {
                    src: '/scorm/docebo.png',
                    width: 117,
                    height: 27
                },
                {
                    src: '/scorm/canvas.png',
                    width: 151,
                    height: 52
                },
                {
                    src: '/scorm/blackboard.png',
                    width: 165,
                    height: 52
                },
                {
                    src: '/scorm/ispring.png',
                    width: 132,
                    height: 30
                },
            ],
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.scorm-logos {
    padding: 120px 64px;
    .txt-component {
        margin-bottom: 24px;
        &.heading {
            color: var(--navy);
            margin-bottom: 12px;
        }
    }
    section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 24px;
        img {
            display: flex;
            flex: 0 0 auto;
            border-radius: 12px;
            max-width: 140px;
            max-height: 52px;
            object-fit: contain;
        }
    }
    &.tablet {
        padding: 80px 64px;
    }
}
</style>
