<template>
    <div
        class="three-col-section section-wrapper"
        :class="[viewport.is, background]"
        :style="{ backgroundColor: `var(--${background})` }"
    >
        <section>
            <Txt
                tag="h2"
                :size="titleSize" heading
                align="center"
            >
                {{ title }}
            </Txt>
            <Txt v-if="description" :size="descriptionSize">
                {{ description }}
            </Txt>
            <div class="grid">
                <div
                    v-for="listItem, listIndex in list"
                    :key="listIndex"
                    class="grid-item"
                >
                    <NuxtImg
                        v-if="listItem.image"
                        :src="listItem.image"
                        :alt="listItem.imageAlt"
                        provider="builderio"
                        width="240"
                        height="240"
                        sizes="xl:25vw lg:25vw md:25vw sm:25vw xs:25vw"
                        loading="lazy"
                        :class="imageStyle"
                    />
                    <Txt
                        tag="h3"
                        :size="listTitleSize" heading
                    >
                        {{ listItem.title }}
                    </Txt>
                    <Txt :size="listDescriptionSize">
                        {{ listItem.description }}
                    </Txt>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useResponsive } from '@/store/responsive';
import { background } from '@/components/author/cms/constants';

import Txt from '@/components/form/txt';

export default {
    name: 'ThreeColumnSection',
    components: { Txt },
    inheritAttrs: false,
    props: {
        title:  { type: String, default: 'Transform it', cmsType: 'longText' },
        titleSize: { type: String, default: 'l', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        description: { type: String, default: null, cmsType: 'longText' },
        descriptionSize: { type: String, default: 'l', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        list: {
            type: Array,
            default: null,
            subFields: [
                { name: 'title', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'webp', 'svg'] },
                { name: 'imageAlt', type: 'string' }
            ]
        },
        listTitleSize: { type: String, default: 's', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        listDescriptionSize: { type: String, default: 'xl', enum: ['xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs', 'xxxs'] },
        imageStyle: { type: String, default: 'icon', enum: ['icon', 'image'] },
        background
    },
    data() {
        return {
            viewport: { is: {} }
        };
    },
    mounted() {
        this.viewport = useResponsive();
    }
};
</script>

<style lang="less" scoped>
.section-wrapper.three-col-section {
    position: relative;
    padding: 96px 40px;
    margin: 32px;
    border-radius: 12px;
    color: var(--navy);
    section {
        gap: 80px;
        hr {
            width: 100%;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }
        > svg {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 64px;
        .grid-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h3 { margin: 0; }
            .txt-component.body {
                font-family: var(--heading);
                font-weight: 200;
            }
            img {
                margin-bottom: 48px;
                border-radius: 16px;
                &.icon {
                    aspect-ratio: 1;
                    object-fit: cover;
                }
            }
        }
    }
    &.tablet {
        padding: 48px;
        .grid {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }
}
</style>
